import React from 'react';
import GlobalStyle from './GlobalStyle';
import styled from 'styled-components/macro';

import { gameplay, Stats, initialStats } from './data';
import { saveGameSession, saveWishes } from './utils';

const Viewport = styled.div({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 400,
});

const ControlsPositioner = styled.div({
  margin: 30,
  height: 540,
  maxHeight: '80vh',

  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const StatNumber = styled.span({
  fontWeight: 500,
});

const StyledStat = styled.div({
});

const StyledStats = styled.div({
  marginBottom: 15,
  paddingTop: 8,
  paddingBottom: 8,

  display: 'flex',
  justifyContent: 'space-around', 

  borderRadius: 5,
  border: '1px solid gray',
  backgroundColor: 'white',
});

const StatsDisplay = ({ stats }: { stats: Stats }) => (
  <StyledStats>
    <StyledStat>
      <span role="img" aria-label="money">💲</span>
      <StatNumber>{stats.money}</StatNumber>
    </StyledStat>
    <StyledStat>
      <span role="img" aria-label="mood">😊</span>
      <StatNumber>{stats.mood}</StatNumber>
    </StyledStat>
    <StyledStat>
      <span role="img" aria-label="sleep">💤</span>
      <StatNumber>{stats.sleep}</StatNumber>
    </StyledStat>
  </StyledStats>
);

const Playground = styled.div({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',

  padding: 16,
  display: 'flex',
  flexDirection: 'column', 
  overflow: 'auto', 

  borderRadius: 5,
  border: '1px solid gray',
  backgroundColor: 'white',
});

const Avatar = styled.div({
  position: 'absolute',
  left: -30,
  bottom: -30,
  width: 50,
  height: 50,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  borderRadius: '50%',
  border: '1px solid gray',
  backgroundColor: 'white',

  fontSize: 24,
});

const StoryContainer = styled.div({
  flexGrow: 1,
   
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'auto',
})

const StoryText = styled.div({
  fontSize: 18,

  whiteSpace: 'pre-line',
});

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'space-around',

  '> *': {
    flex: '50%',
    marginLeft: 5,
    marginRight: 5,
  }
});

const Button = styled.button({
  padding: 4,

  backgroundColor: 'white',
  border: '1px solid pink',
  borderRadius: 5,
});

const Diff = ({ value, symbol }: { value: number; symbol: string }) => value !== 0 ? (
  <div>
    <span>{symbol}</span>
    {value > 0 && (<span role="img" aria-label="arrow up">⬆</span>)}
    {value < 0 && (<span role="img" aria-label="arrow down">⬇</span>)}
  </div>
) : (<></>);

const StyledStatsDiff = styled.div({
  marginTop: 15,
  marginBottom: 15,
  
  display: 'flex',

  justifyContent: 'space-around',
});

const StatsDiff = ({ stats }: { stats: Stats }) => (
  <StyledStatsDiff>
    <Diff value={stats.money} symbol="💲" />
    <Diff value={stats.mood} symbol="😐" />
    <Diff value={stats.sleep} symbol="💤" />
  </StyledStatsDiff>
);

const EndStory = () => (
  <div>
    <h2>
      To be continued...
    </h2>
    <p>
      You did well, but not as well as the real hero of this adventure - Genia Trofimova!
    </p>
    <p>
      It's her birthday today!!! <span role="img" aria-label="party emoji">🥳</span>
    </p>
  </div>
);

const WishesTextArea = styled.textarea({
  height: '100%',
  width: '100%',
  border: '1px solid lightgray',
  borderRadius: 5,
  padding: 10,
  boxSizing: 'border-box',
  marginBottom: 20,
  marginLeft: 5,
  marginRight: 5,
});

const Footer = styled.footer({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: -24,

  textAlign: 'center',
  fontSize: 12,
  color: '#aaa',

  'a': {
    color: '#aaa',
  }
});

const initialLevelId = 'start';

const Game = () => {
  const [levelId, setLevelId] = React.useState(initialLevelId);
  const [stats, setStats] = React.useState(initialStats);
  const [sequence, setSequence] = React.useState([initialLevelId]);
  const [gameSession, setGameSession] = React.useState('');
  const [isWishes, setIsWishes] = React.useState(false);
  const [isThankYou, setIsThankYou] = React.useState(false);
  const advanceLevel = (nextLevelId: string) => {
    setLevelId(nextLevelId);
    setSequence(sequence.concat(nextLevelId));
    const nextLevel = gameplay[nextLevelId];
    const newStats = stats;
    if (nextLevel.statsDiff) {
      newStats.money += nextLevel.statsDiff.money;
      newStats.mood += nextLevel.statsDiff.mood;
      newStats.sleep += nextLevel.statsDiff.sleep;
    }
    setStats(newStats);
  }
  const level = gameplay[levelId];

  const isEnd = levelId.startsWith('end-');

  React.useEffect(() => {
    if (isEnd) {
      const session = saveGameSession(sequence);
      setGameSession(session);
    }
  }, [isEnd, sequence]);

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  
  return (
    <>
      <GlobalStyle />
      <Viewport>
        <ControlsPositioner>
          <StatsDisplay stats={stats} />
          <Playground>
            {!isEnd && (
              <>
                <StoryContainer>
                  <div>
                    <StoryText>
                      {level.text}
                    </StoryText>
                    {level.statsDiff && <StatsDiff stats={level.statsDiff} />}
                  </div>
                </StoryContainer>
                <Actions>
                  {level.actions.map(action => (
                    <Button key={action.next} onClick={() => advanceLevel(action.next)}>
                      {action.text}
                    </Button>
                  ))}
                </Actions>  
              </>
            )}
            {isEnd && !isWishes && !isThankYou && (
              <>
                <StoryContainer>
                  <EndStory />
                </StoryContainer>
                <Actions>
                  <Button onClick={() => setIsWishes(true)}>
                    Say kind wishes and nice words
                  </Button>
                </Actions>
              </>
            )}
            {isWishes && (
              <>
                <StoryContainer>
                  <WishesTextArea placeholder="Nice words only :-)" ref={textAreaRef} defaultValue="" />
                </StoryContainer>
                <Actions>
                  <Button onClick={() => {
                    saveWishes(gameSession, textAreaRef.current!.value);
                    setIsWishes(false);
                    setIsThankYou(true);
                  }}>
                    Send
                  </Button>
                </Actions>
              </>
            )}
            {isThankYou && (
              <>
                <StoryContainer>
                  <h2>
                    Thank you!
                  </h2>
                </StoryContainer>
              </>
            )}            
          </Playground>
          <Avatar>
            <span role="img" aria-label="avatar">{level.avatar}</span>
          </Avatar>
          <Footer>
            <span role="img" aria-label="bear emoji">🐻</span> from
            {' '}
            <a href="https://introwise.com" target="_blank" rel="noopener noreferrer">Introwise</a>
          </Footer>
        </ControlsPositioner>
      </Viewport>
    </>
  )
};

export default Game;
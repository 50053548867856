import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDBn7bfXqYxU-WgXqZCk3c1qp5w9KOewWg",
  authDomain: "adventuregenia.firebaseapp.com",
  databaseURL: "https://adventuregenia.firebaseio.com",
  projectId: "adventuregenia",
  storageBucket: "adventuregenia.appspot.com",
  messagingSenderId: "243178070480",
  appId: "1:243178070480:web:358d3432ff7fecd5"
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

export const saveGameSession = (sequence: string[]): string => {
  const newSessionDoc = firestore.collection('sessions').doc();
  newSessionDoc.set({
    sequence,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  });
  return newSessionDoc.id;
}

export const saveWishes = (session: string, wishes: string) => {
  const sessionDoc = firestore.collection('sessions').doc(session);
  sessionDoc.update({
    wishes,
  });
}
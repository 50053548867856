import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle({
 body: {
    margin: 0,
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    backgroundColor: '#282c34',
 }
});

export default GlobalStyle;

const genia = '👩';
const ayaEvil = '😼';
const ayaLove = '😻';

export type Stats = {
  money: number;
  mood: number;
  sleep: number;
}

type Action = {
  text: string;
  next: string;
}

type Level = {
  text: string;
  avatar: string;
  actions: Action[];
  statsDiff?: Stats;
}

type Gameplay = {
  [key: string]: Level;
}

const gameplay: Gameplay = {
  'start': {
    text: 'Hi! This is Genia, CEO and co-founder of Introwise\n\n\n And this is her adventure',
    avatar: genia,
    statsDiff: {
      sleep: 0,
      mood: 0,
      money: 0,
    },
    actions: [
      {
        text: 'Let\'s go!',
        next: 'aya-alarm',
      },
    ]
  },
  'aya-alarm': {
    text: `You woke up to a loud meowing of your cat - Aya. \n\n - It's 5 a-aaaaam, she sings`,
    avatar: ayaEvil,
    statsDiff: {
      sleep: -1,
      mood: -1,
      money: 0,
    },
    actions: [
      {
        text: 'Urgh...',
        next: 'aya-love'
      }
    ]
  },
  'aya-love': {
    text: `But Aya is still the best in the world and you love her so much ❤️❤️❤️`,
    avatar: ayaLove,
    statsDiff: {
      sleep: 0,
      mood: +1,
      money: 0,
    },
    actions: [
      {
        text: 'Yes she is!',
        next: 'sell-or-build',
      }
    ]
  },
  'sell-or-build': {
    text: `Back to work! \n\nIntrowise MVP has been just built and tested. Should we start selling it or keep adding features?`,
    avatar: '🛠',
    actions: [
      {
        text: `It's viable! Start selling`,
        next: 'sales',
      },
      {
        text: `Not good enough. Build features`,
        next: 'features-built'
      }
    ]
  },
  'sales': {
    text: `Should we hire a salesperson?`,
    avatar: '💼',
    actions: [
      {
        text: 'Yes, outsource',
        next: 'sales-outsource'
      },
      {
        text: 'No, do it yourself',
        next: 'sales-diy'
      }
    ]
  },
  'sales-outsource': {
    text: `Sales are going well!`,
    avatar: '🤑',
    statsDiff: {
      money: +1,
      mood: +2,
      sleep: 0,
    },
    actions: [
      {
        text: 'Keep going',
        next: 'sales-fiasco'
      }
    ]
  },
  'sales-fiasco': {
    text: `Turned out your sales hire was using a bought list of emails.\n\n80% of your sent emails are now going to spam`,
    avatar: '💩',
    statsDiff: {
      money: 0,
      mood: -2,
      sleep: 0,
    },
    actions: [
      {
        text: 'Fire them!',
        next: 'rest',
      }
    ]
  },
  'sales-diy': {
    text: `You didn't close any sales, but have collected a ton of user feedback`,
    avatar: '👎',
    statsDiff: {
      money: 0,
      mood: 0,
      sleep: -1,
    },
    actions: [
      {
        text: `They call it 'lean' startup because you're constantly starving?`,
        next: 'rest'
      }
    ]
  },
  'features-built': {
    text: `Whooohoo! Your CTO built an AI-recommendation algorithm 3 days ahead of the schedule.\n\n Should we built a few more features?`,
    avatar: '🤖',
    statsDiff: {
      money: 0,
      mood: +1,
      sleep: +1,
    },
    actions: [
      {
        text: `No. Let's ship it`,
        next: 'customer-validation',
      },
      {
        text: `Keep building!`,
        next: 'facebook-bummer',
      }
    ]
  },
  'customer-validation': {
    text: `The first users love your product!\nAnd you even got a few paying customers`,
    avatar: '💰',
    statsDiff: {
      money: +1,
      mood: 0,
      sleep: 0,
    },
    actions: [
      {
        text: 'Just as planned!',
        next: 'rest',
      }
    ]
  },
  'facebook-bummer': {
    text: `You've spent 3 weeks building new 'Event Stories' feature and Facebook have just released exactly the same one.\n\nYou have to scrap all the work`,
    avatar: '👊',
    statsDiff: {
      money: 0,
      mood: -1,
      sleep: -1,
    },
    actions: [
      {
        text: '#deletefacebook',
        next: 'meltdown',
      }
    ],
  },
  'meltdown': {
    text: `After working sleepless night on a failed feature your CTO had a mental breakdown and ran away in the woods`,
    avatar: '🤪',
    actions: [
      {
        text: `He'll come back`,
        next: 'running-with-bears',
      },
      {
        text: 'Fire him!',
        next: 'single-founder'
      }
    ]
  },
  'single-founder': {
    text: `He deserved it 😒 \n\n But customers keep asking for new features. Your experienced friend Anton ready to help for just 42% of the company ownership`,
    statsDiff: {
      money: 0,
      mood: -1,
      sleep: 0,
    },
    avatar: '🙋‍♂️',
    actions: [
      {
        text: `It's a trap. Hire freelancers`,
        next: 'freelancer-dev'
      },
      {
        text: `Been there, done that - hire Anton!`,
        next: 'antosha'
      }
    ]
  },
  'freelancer-dev': {
    text: `You had to use all your PM skills and time to organize the developers, but you shipped the new features in time!`,
    avatar: '🧙‍♀️',
    statsDiff: {
      money: 0,
      mood: +1,
      sleep: -1,
    },
    actions: [
      {
        text: 'Proud CMU MSIT-SE alumnus ✊',
        next: 'rest'
      }
    ]
  },
  'antosha': {
    text: `Antosha talked you into another pivot. Now you're in a fish business!`,
    statsDiff: {
      money: 0,
      mood: +2,
      sleep: -2,
    },
    avatar: '🐙',
    actions: [
      {
        text: 'Antosha 😍',
        next: 'rest'
      }
    ]
  },
  'undefined': {
    text: 'Uncaught exception ocurred: Not Implemented',
    avatar: '❌',
    actions: [
      {
        text: 'Go back',
        next: 'meltdown'
      }
    ]
  },
  'running-with-bears': {
    text: `The woods are crowded nowadays, so while running CTO recruited a few new customers.\n\n They are probably bears, but who cares?`,
    avatar: '🐻',
    statsDiff: {
      money: +1,
      mood: +1,
      sleep: 0,
    },
    actions: [{
      text: `Welcome early adopters!`,
      next: 'rest'
    }],
  },
  'rest': {
    text: `Time for some rest`,
    avatar: '😎',
    actions: [
      {
        text: 'Go dancing',
        next: 'dancing'
      },
      {
        text: 'Go sleeping',
        next: 'sleeping'
      },
      {
        text: 'Go running',
        next: 'running'
      }
    ]
  },
  'dancing': {
    text: `You've spent half the night dancing because you're damn good at it`,
    avatar: '💃',
    statsDiff: {
      money: 0,
      mood: +2,
      sleep: -1,
    },
    actions: [
      {
        text: `🎶 I've got the feeling 🎶`,
        next: 'conference-or-pitch'
      }
    ]
  },
  'running': {
    text: `This 10K felt good. You're definitely in shape for the upcoming marathon`,
    avatar: '🏃‍♀️',
    statsDiff: {
      money: 0,
      mood: +1,
      sleep: 0,
    },
    actions: [
      {
        text: `Savour the runner's high`,
        next: 'conference-or-pitch'
      }
    ]
  },
  'sleeping': {
    text: `Sleep is a luxury founders cannot afford`,
    avatar: '🛌',
    statsDiff: {
      money: -1,
      mood: 0,
      sleep: +1,
    },
    actions: [
      {
        text: 'Zzzzzzzz...',
        next: 'conference-or-pitch'
      }
    ]
  },
  'conference-or-pitch': {
    text: `Need to create some buzz for the startup!`,
    avatar: '🐝',
    actions: [
      {
        text: 'Attend a conference',
        next: 'conference-flight'
      },
      {
        text: 'Go to a pitch competition',
        next: 'pitch'
      }
    ]
  },
  'conference-flight': {
    text: 'The conference is in another city and the flights are crazy expensive',
    avatar: '✈',
    actions: [
      {
        text: 'Fly direct',
        next: 'flight-direct'
      },
      {
        text: 'Mix 2 cheap airlines and a bus',
        next: 'flight-cheap'
      }
    ]
  },
  'flight-direct': {
    text: 'You saved a day of travelling and now have some extra time before the conference',
    avatar: '⏱',
    statsDiff: {
      money: -1,
      mood: 0,
      sleep: +1,
    },
    actions: [
      {
        text: 'Go to pre-conference networking',
        next: 'pre-conference-networking'
      },
      {
        text: 'Explore the city',
        next: 'pre-conference-explore'
      },
      {
        text: 'Work from the hostel',
        next: 'pre-conference-work'
      }
    ]
  },
  'pre-conference-networking': {
    text: 'But how do you network without Introwise?',
    avatar: '🤔',
    statsDiff: {
      money: 0,
      mood: -1,
      sleep: 0,
    },
    actions: [
      {
        text: 'Right...',
        next: 'conference'
      }
    ]
  },
  'pre-conference-explore': {
    text: 'Food and transport costs a fortune in this city!',
    avatar: '🗼',
    statsDiff: {
      money: -1,
      mood: +1,
      sleep: 0,
    },
    actions: [
      {
        text: 'Was worth it',
        next: 'conference'
      }
    ]
  },
  'pre-conference-work': {
    text: '"Hey, Genia, when will you send that lecture plan we talked about? -Leelo, U of Tartu"\n\n Yep, founders have part-time jobs',
    avatar: '🙄',
    statsDiff: {
      money: 0,
      mood: -1,
      sleep: 0,
    },
    actions: [
      {
        text: 'Send a rough draft',
        next: 'uni-draft'
      },
      {
        text: 'Finish the plan',
        next: 'uni-full'
      }
    ]
  },
  'uni-draft': {
    text: '"Looks o-kay... -Leelo"',
    avatar: '🤷‍♀️',
    statsDiff: {
      money: +1,
      mood: -1,
      sleep: 0,
    },
    actions: [
      {
        text: 'That was a Minimal Viable Plan',
        next: 'conference'
      }
    ]
  },
  'uni-full': {
    text: '"Looks great! You must\'ve spent a ton of time on it. -Leelo"',
    avatar: '😴',
    statsDiff: {
      money: +1,
      mood: 0,
      sleep: -1,
    },
    actions: [
      {
        text: 'Perfect or nothing!',
        next: 'conference'
      }
    ]
  },
  'flight-cheap': {
    text: 'The cheapest option was through Ohrid, Macedonia, which turned out to be a beautiful place',
    avatar: '🌞',
    statsDiff: {
      money: 0,
      mood: +1,
      sleep: -1,
    },
    actions: [
      {
        text: 'Country #46!',
        next: 'conference'
      }
    ]
  },
  'conference': {
    text: 'The conference was exhausting but productive and you\'ve gathered a few useful contacts',
    avatar: '📝',
    statsDiff: {
      money: +1,
      mood: -1,
      sleep: 0,
    },
    actions: [
      {
        text: 'Can I add you on LinkedIn?',
        next: 'pre-end'
      }
    ]
  },
  'pitch': {
    text: 'Your pitch was killin\' but the winner was a friend of a an organizer',
    avatar: '😨',
    statsDiff: {
      money: 0,
      mood: -1,
      sleep: 0,
    },
    actions: [
      {
        text: 'Friends first',
        next: 'pre-end'
      }
    ]
  },
  'pre-end': {
    text: 'What should you do next?',
    avatar: '❔',
    actions: [
      {
        text: 'Raise VC money',
        next: 'end-raise'
      },
      {
        text: 'Keep bootstrapping',
        next: 'end-bootstrap'
      },
      {
        text: 'Go through an accelerator',
        next: 'end-accelerator'
      }
    ]
  },
  'end-raise': {
    text: '',
    avatar: '🙌',
    actions: [],
  },
  'end-bootstrap': {
    text: '',
    avatar: '🙌',
    actions: [],
  },
  'end-accelerator': {
    text: '',
    avatar: '🙌',
    actions: [],
  }
}

const initialStats: Stats = {
  money: 1,
  mood: 1,
  sleep: 1,
}

export { gameplay, initialStats };
